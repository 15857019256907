
//实际使用

import { Message } from "element-ui";
import api from "@/api";
export default {
  props: {},
  data() {
    return {
      form: {
        userName: "",
        passWord: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    doLogin() {
      let that = this
      this.$refs["LoginForm"].validate((valid) => {
        if (valid) {
          this.$getApi(api.login+'?username='+that.form.userName+'&password='+that.form.passWord).then((res) => {
            if(res.code === '1'){
                Message.success("登录成功!");
                sessionStorage.setItem("token", "admin");
                this.$router.push({ name: "Home" });
            }else{
              Message.error("登录失败!");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {
    let that = this
    window.addEventListener("keydown",(event) => {
          console.log(event.key);
          if(event.key === 'Enter'){
              that.doLogin();
          }
        },
        true
    );
  }
};
